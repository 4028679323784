import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import ImageUtil from '../../utils/ImageUtil';
import { to2Decimal, formatINR } from '../../utils/CommonUtil';

const FinalOrderList = (props) => {
    //console.log(props)
    const [showAll, setShowAll] = useState(false);
    const toggleShow = () => {
        setShowAll(!showAll)
    }
    const renderItem = (item, indx) => {
        return (
            <Row key={item.name+indx} className="final-ord-list">
                    {/* <Col md="1" xs="1" className="pr-0 pl-2 pt-3 text-center">
                        <p className="mb-0 font-12"><b>{item.quantity}</b></p>
                    </Col> */}
                    <Col md="2"  xs="3" className="p-0-tab d-flex jc-flc">
                        {item.brandicon ?
                            <div className="cart-brand-iconmaindiv">
                                <img className="cart-brand-img" alt={item.brandicon} src={ImageUtil.getImage(item.brandicon)} />
                            </div> : null}
                        <img style={{ width: "100%" }}
                            alt={item.name}
                            className="product-image"
                            src={item.images[0] ? ImageUtil.getImage(item.images[0].name) : ImageUtil.getErrorImage() }
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = ImageUtil.getErrorImage();
                            }}
                        />
                    </Col>
                    <Col md="8"  xs="5" className="p-0">
                        <Row><Col className="text-left"><span className="badge badge-pill badge-success">{item.promo_percentage}</span></Col></Row>
                        <Row>
                            <Col md="12" className="text-left">
                                <p className="product-name product-name-cart mb-0 text-muted fs-13-f-n">{item.name}</p>
                            </Col>
                        </Row>
                        <Row><Col className="text-muted text-left font-12">{item.size} × {item.quantity}</Col></Row>
                        
                    </Col>
                    <Col md="2" xs="4" className="text-right d-flex flex-column justify-content-center">
                        <p className="amount product-price text-right mb-0 fs-14">{item.promo_price ? formatINR(to2Decimal(item.quantity * item.promo_price)) : formatINR(to2Decimal(item.quantity * item.price_regular))}</p>
                    </Col>
                </Row>
        )
    }
    const renderAllItems = () => {
        let items = props.cartItems.map((item,i) => {
            return renderItem(item,i)})
        return items;
    }

    const renderfourItems = () => {
        let items = props.cartItems.map((item, i )=> {
           return( i< 4 ? renderItem(item,i) :  "")}) 
        return items;
    }
    return (
        <Card>

            <Row>
                <Col className="pt-2 pb-2 bord-bot-5">
                    <div><p className="addressbrands-second ">Grand Total<span className="float-right text-dark">{formatINR(to2Decimal(props.checkoutDetails.totalamt))}</span></p></div>
                    <div><p className="addressbrands-second ">Total Tax<span className="float-right text-success">{formatINR(to2Decimal(props.checkoutDetails.totaltaxamt))}</span></p></div>
                    <div><p className="addressbrands-second ">Delivery Charges<span className="float-right text-success">{formatINR(to2Decimal(props.checkoutDetails.deliverychargeamt))}</span></p></div>
                    <hr className="mt-2 mb-2" />
                    <div><p className="addressbrands-second ">Your Total Savings<span className="float-right text-danger">{formatINR(to2Decimal(props.checkoutDetails.discount_total))}</span></p></div>
                </Col>
            </Row>
            
            <Card.Header className="bg-secondary text-white mt-2">
                <Row>
                    <Col className="p-0-mob p-0 p-0-tab">My Items ({props.cartItems.length})</Col>
                    <Col className="text-right p-0 p-0-mob p-0-tab">{formatINR(to2Decimal(props.checkoutDetails.grandtotalamt))}</Col>
                </Row>
            </Card.Header>
            <Col className="pl-0 pr-0 text-center">
            {showAll ? renderAllItems() : renderfourItems()}
            </Col>
            <Card.Footer className="text-center">
                { props.cartItems.length > 4 ? <button className="btn btn-link" onClick={toggleShow}>{showAll ? "Show Less" : "Show More"}</button> : ""}
            </Card.Footer>
        </Card>
    )
}
const mapStateToProps = state => ({
    cartItems: state.CartData.cartList,
    totalAmount: state.CartData.totalAmount,
    checkoutDetails: state.CheckoutData.checkoutDetails
});
export default connect(mapStateToProps)(FinalOrderList);
import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';

import {host} from '../utils/Env';

const COMPANY_INFO = "COMPANY_INFO";

/**
 * Action  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const getCompanyInfo = () => {
    console.log(`HOST ${host()}`);
    return apiAction({
        url: `${API.GET_COMPANY_INFO}${host()}`, 
        onSuccess: setCompanyInfo,
        onFailure: () => console.log("Error occured in getCompanyInfo"),
        callback: 'getCompanyInfo',
        label: COMPANY_INFO
      });
}

export const setCompanyInfo = param => ({
    type: COMPANY_INFO,
    payload: param.data
  });


/**
 * set up with initial state.
 */
const initialState = {
    companyid: ''
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        //case COMPANY_ID: return {...state, companyid: action.payload.companyid, logo:action.payload.logo }
        case COMPANY_INFO: return {...state, ...action.payload }
        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const HomeAction = {
    reducer: reducer,
    actions: {
        getCompanyInfo
    }
}

export default HomeAction
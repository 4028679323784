import React from "react";
import ImageUtil from '../../utils/ImageUtil';
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";

const Category = props => {

  const handleMouseOver = e => {
    //console.log("handleMouseOver ");
  }

  const handleMouseOut = e => {
    //console.log("handleMouseOut ")
  }

  const getIcon = icon => {
    return (
      <label className="mb-0">
        <img className="icon-image hidden-xs hidden-sm" alt={icon} src={ImageUtil.getImage(icon)} />&nbsp;&nbsp;
        <br className="hidden-xs"/>
      </label>
    ) 
  }

  return (
    <Nav.Item key={props.keyValue} 
    onMouseEnter={handleMouseOver}
    onMouseLeave={handleMouseOut}>
      <Nav.Link eventKey={props.category.id} active={props.selectedCategory === props.category.id ? true : false}>
      {props.category.icon && getIcon(props.category.icon)}{props.category.name}    
      </Nav.Link>
    </Nav.Item>
  );
};
const mapStateToProps = state => ({
  selectedCategory: state.CategoryData.selectedCategory,
})
export default connect(mapStateToProps)(Category);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col} from 'react-bootstrap';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import Address from '../components/checkout/Address';
import FinalOrderList from '../components/checkout/FinalOrderList';
import PhoneValidate from '../components/checkout/PhoneValidate';
import Delivery from '../components/checkout/Delivery';
import GenericModal from '../components/checkout/OrderConfirmationModal';
import Payment from '../components/checkout/Payment';
import Footer from '../components/Footer';

const CheckOut = props => {

    const [phnCard, setPhnCard] = useState(true);
    const [addressCard, setAddressCard] = useState(false);
    const [deliveryCard, setDeliveryCard] = useState(false);
    const [paymentCard, setPymentCard] = useState(false);

    //const [checkoutState, setCheckOutState] = useState([0,0,0,0]);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        navigate(location.state && (location.state.process) ? "/checkout" : "/products", {state:{process:false}, replace: true});
        //to be only used for 101 hooks
        //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


    const disableAllState = () => {
        setPhnCard(true);
        setAddressCard(false);
        setDeliveryCard(false);
        setPymentCard(false);
    }

    /* useEffect(() => {
        console.log('card changed ', phnCard, addressCard, deliveryCard, paymentCard);
    }, [phnCard, addressCard, deliveryCard, paymentCard]); */

    return (
        <React.Fragment>
            <div className="action-block action-blockpopup pos-fixedclass bot-autofd-div">
                <Container className="p-0-mob p-0-tab">
                    <div className="chk-out-button  text-left bx-shadow-withbord">
                        <Link to='/products' className="product-links-anchor pl-3 pr-3">Back to Shopping</Link>
                    </div>
                </Container>
            </div>
            <Container className="p-0-mob p-0-tab">
                <Row className="product-details-maindiv top-50-martop">
                    <Col md="6 mb-2">
                        <PhoneValidate  setAddressCard={setAddressCard} phnCard={phnCard} setPhnCard={setPhnCard} disableAllState={disableAllState}/>
                        <Address  setDeliveryCard={setDeliveryCard} setAddressCard={setAddressCard} addressCard={addressCard}/>
                        <Delivery  setPymentCard={setPymentCard} setDeliveryCard={setDeliveryCard} deliveryCard={deliveryCard}/>
                        <Payment  paymentCard={paymentCard}/>
                    </Col>
                    <Col md="6">
                        <div><FinalOrderList /></div>
                    </Col>
                </Row>
            </Container>
            <Footer />
            <GenericModal />
        </React.Fragment>
    );
}

export default connect()(CheckOut);  

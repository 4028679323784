import React, {useEffect} from 'react';
//import { useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ImageUtil from '../utils/ImageUtil';
import { clearCategory } from '../actions/CategoryAction';
import { connect } from 'react-redux';

const Brands = (props) => {

    //.. local data
    /* const associate_brands = [{
        text:'WBCADC',
        image:'bbangla.png'
    },{
        text:'wbcadc',
        image:'wbcadc.jpg'
    },{
        text:'Haringhata',
        image:'haringhata_meat.jpg'
    },{
        text:'Natural',
        image:'logo_natural100.jpg'
    },{
        text:'Gogras',
        image:'gogars-logo.png'
    }]; */

    const associate_brands = props.brands;

    const navigate = useNavigate();

    const handleClick = param => e => {
        e.preventDefault();
        props.clearCategory();
        navigate('/search', {state:{term:param}});
    };

    const renderBrands = () => {

        let bItems = associate_brands.map((item, index) => {
            return (
                <Col key={index} className="bord-right1px p-0-tab cursor" onClick={handleClick(item.text)}>
                    <div className="branding-div branding-div-first pr-2">
                        <img alt={item.text} src={ImageUtil.getImage(item.image)} />
                    </div>
                    {/* <div className="branding-div branding-div-second pr-2"><h3 className="brands-name">{item.text}</h3></div> */}
                </Col>
            ) 
        })

        return (
            <div className="branding-section" >
                <div className="container p-0-xs p-0-tab">
                    <Row>
                        {bItems}
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>

            {/* <div className="branding-section" >
                    <div className="container p-0-xs p-0-tab">
                        <div className="row">
                            <Col md={3} xs={5} className="bord-right1px p-0-tab cursor" onClick={handleClick(associate_brands[0].text)}>
                                <div className="branding-div branding-div-first pr-2">
                                    <img alt={associate_brands[0].text} src={ImageUtil.getImage(associate_brands[0].image)} />
                                </div>
                                <div className="branding-div branding-div-second pr-2"><h3 className="brands-name">WBCADC</h3></div>
                                <div className="branding-div branding-div-third pr-2">
                                    <img alt={associate_brands[1].text} src={ImageUtil.getImage(associate_brands[1].image)} />
                                </div>
                            </Col>
                            <Col md={5} xs={2} className="bord-right1px text-center p-0-tab cursor" onClick={handleClick(associate_brands[2].text)}>
                                <div className="branding-div branding-div-sec4 txt-center pl-4 pr-4"><p className="addressbrands addressbrands-second">Enjoy</p><p className="addressbrands addressbrands-second">The Taste of quality meat</p></div>
                                <div className="branding-div branding-div-haringhata"><img alt={associate_brands[2].text} src={ImageUtil.getImage(associate_brands[2].image)} /></div>
                            </Col>
                            <Col md={2} xs={2} className="bord-right1px cursor" onClick={handleClick(associate_brands[3].text)}>
                                <div className="branding-div branding-div-haringhata pl-5 p-0-tab"><img alt={associate_brands[3].text} src={ImageUtil.getImage(associate_brands[3].image)} /></div>
                            </Col>
                            <Col md={2}  xs={3} className="col-md-2 text-center cursor" onClick={handleClick(associate_brands[4].text)}>
                                <div className="branding-div branding-div-gogras pt-3"><img alt={associate_brands[4].text} src={ImageUtil.getImage(associate_brands[4].image)} /></div>
                            </Col>
                        </div>
                        
                    </div>
                </div> */}
           
            {props.brands ? renderBrands() : ''}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    brands:state.HomeData.brands
  })

export default connect(mapStateToProps, { clearCategory }) (Brands);
import { config } from '../config';

export default class ImageUtil {

    static getErrorImage() {
        return config.app.IMG_URL + "not_available.jpg"
    }
    
    static getImage(img){
        return (img) ? (config.app.IMG_URL + img) : ImageUtil.getErrorImage()
    }
}



import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import ThemeSwitcher from 'react-css-vars';

import Header from '../components/header/Header';
import Footer from '../components/Footer';
import Brands from '../components/Brands';

import { getProductsList} from '../actions/ProductsAction';
import { setCheckOutCartState } from '../actions/CartAction';

//import {getIP} from '../actions/LocationAction';
import { isDev, Global } from '../utils/Env';

import { config } from '../config';

const HomeContainer = props => {

  const {companyid, imagepath, brands, theme, setCheckOutCartState, getProductsList } = props;
  //.. initial call for company and app setup

  const [defaultTheme, setDefaultTheme] = useState({
    themecolor: "#177913",
    mobileCardHeight: '370px',
    desktopCardHeight: '480px'
  });

  Global.callback.getCompanyInfo_onComplete = () => {
    if(!isDev()) {
      config.app.IMG_URL = imagepath;
    }
  }

  useEffect(() => {
      setCheckOutCartState(false);
  }, []);
  
  useEffect(() => {
    if(companyid && companyid !== '') {
      getProductsList(companyid);
      //console.log('getProductsList called with companyid ', companyid);
    }
  }, [companyid]);

    useEffect(() => {
      let {themecolor, mobileCardHeight, desktopCardHeight} = defaultTheme;
      if(theme) if(theme.color) setDefaultTheme({
        themecolor : theme.color ? theme.color : themecolor,
        mobileCardHeight: theme.mobile_card_height ? theme.mobile_card_height : mobileCardHeight,
        desktopCardHeight: theme.desktop_card_height ? theme.desktop_card_height : desktopCardHeight
      });
    }, [theme]);
    
    return (
      <React.Fragment>
        <ThemeSwitcher theme={defaultTheme} elementId="root">
        <header>
         <Header />
        </header>
        <div className="full-Pagesection">
          {brands && <Brands />}        
          <Outlet />
        </div>
        <Footer />
        {/* <div className="branding-section" >
        
        <div className="container">
        <div className="row">
         <div className="col-md-4">
          <div className="branding-div branding-div-first">
            <img src="https://ecommercedev.wizardcomm.net/different/location/images/bbangla.png"/>
           </div>
           <div className="branding-div branding-div-second"><h3 className="brands-name">WBCADC</h3></div>
           <div className="branding-div branding-div-third">
             <img src="https://ecommercedev.wizardcomm.net/different/location/images/wbcadc.jpg"/>
           </div>
         </div>
         <div className="col-md-4">

         </div>
         <div className="col-md-2">

         </div>
         <div className="col-md-2">

         </div>
         </div>
        </div>
       
      </div> */}
        {/*<Carousel itemsToShow={1}>
          {items.map(item => <div style={divStyle} key={item.id}>{item.title}</div>)}
        </Carousel>
        <hr />
          <Carousel enableAutoPlay autoPlaySpeed={1500}>
          {items.map(item => <div style={this.divStyle2} key={item.id}>{item.title}</div>)}
        </Carousel> 
        <hr /> 
        <Carousel verticalMode itemsToShow={3}>
          {items.map(item => <div style={divStyle} key={item.id}>{item.title}</div>)}
        </Carousel>*/}
        </ThemeSwitcher>
      </React.Fragment>
    );
  
}

const mapStateToProps = state => ({
  companyid:state.HomeData.companyid,
  brands:state.HomeData.brands,
  theme:state.HomeData.theme,
  imagepath:state.HomeData.imagepath,
  productsList: state.ProductsData.productsList
})

export default connect(mapStateToProps, { getProductsList, setCheckOutCartState })(HomeContainer);
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import Product from '../components/Product';
import {ScrollInView} from '../components/Scroll';

const CategoryContainer = (props) => {

    const renderProducts = () => {
        let productsData;
        if(props.productsList) {
            productsData = props.productsList.filter(item => {
                return item.category === props.selectedCategory
                }).map((product) => {
                    const cartObj = props.cartList.filter(item => {
                        return item.id === product.id
                        })
                    return (<Product
                        key={product.id}
                        product={product}
                        cart={cartObj[0]}
                    />)
                })
        }
        return productsData;
      }
      
    let categoryView = renderProducts()
        
    return (
        <ScrollInView trigger={props.selectedCategory}>
            <Container className="p-0-mob">
                <div className="products-wrapper">  
                    <div className="row">
                    {categoryView}
                    </div>
                </div>
            </Container>    
        </ScrollInView>
    );

  }


const mapStateToProps = state => ({
    productsList: state.ProductsData.productsList,
    selectedCategory: state.CategoryData.selectedCategory,
    cartList: state.CartData.cartList 
})

export default connect(mapStateToProps)(CategoryContainer);  
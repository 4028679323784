import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getCategoryList, selectCategory, setMenuState } from '../../actions/CategoryAction';
import Category  from './Category';
import { Nav } from 'react-bootstrap';
import { filterByCategory } from '../../actions/ProductsAction';

const CategoryMenu = (props) => {

    const {companyid, getCategoryList } = props;

    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line
    useEffect(() => { 
        if(companyid && companyid !== '') {
            getCategoryList(companyid)
          }
    }, [companyid]);
    
    useEffect(() => {
        props.selectCategory(location.pathname.replace('/category/',''))
    },[location.pathname]);
    
    const onCategorySelect = (eventKey) => {
        props.selectCategory(eventKey);
        props.filterByCategory(eventKey);
        if(props.isMobileMenu) {
            props.setMenuState(false)
        }
        navigate(`/category/${eventKey}`);
    }
    
    return (
        <div className={props.menuState ? "menu-items" : "hideclassmain"}>
            <div className="categories-section">
               
                <Nav justify variant="pills" className="nav-box" onSelect={onCategorySelect} >
                    {props.CategoryList.map((category, key) => (
                        <Category category={category} key={key} keyValue={key}/>
                    ))}
                    {/* <a className="d-flex flex-column justify-content-center mob-hide arrow-style"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></a> */}
                </Nav>
                
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    CategoryList: state.CategoryData.categoryList,
    menuState: state.CategoryData.menuState,
    isMobileMenu: state.CategoryData.isMobileMenu
})

export default connect(mapStateToProps, {getCategoryList, selectCategory, filterByCategory, setMenuState})(CategoryMenu);
import React from 'react';
//import {Alert, Row, Col } from 'react-bootstrap';
//import Actions, { DispatchAction } from '../actions';
import { connect } from 'react-redux';
import {versionInfo} from '../utils/CommonUtil';

const Footer = props => {
  return (
    <footer>
      <p>
      {versionInfo()} &copy; 2020 <strong><a href="http://wizardcomm.net">WizardComm</a> - <a href="http://uvanij.com">uVanij.com</a></strong>
      </p>
    </footer>
  );

}

const mapStateToProps = state => ({
  
})

export default connect(mapStateToProps)(Footer);